import React from "react"

import LinkExternal from "./link_external"

const Article = ({ author, title, to, quote }) => (
  <div>
    <LinkExternal to={to}>{title}</LinkExternal>
    <span style={{ padding: "0 10px" }}>&mdash;</span>
    <i>{author}</i>
    <br />
    {quote && (
      <blockquote style={{ paddingTop: 5, marginBottom: 10 }}>
        &ldquo;{quote}&rdquo;
      </blockquote>
    )}
  </div>
)

export default Article
